.ContainerPerformanceListDiv {
    background-color: #ffffff;
}

.ContainerPerformanceGood {
    color: green;
}

.ContainerPerformanceBad {
    color: red;
}

.pulseIcon {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 75%;
    background: rgb(25, 99, 196);
    border: 5px solid  rgb(25, 99, 196);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(18, 60, 198, 0.4);
    animation: pulse 7s infinite;
}

.pulseIconError {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 75%;
    background: orange;
    border: 5px solid  rgb(25, 99, 196);
    cursor: pointer;
    box-shadow: 0 0 0 rgba(237, 10, 10, 0.4);
    animation: pulseError 7s infinite;
}

.pulseIcon:hover {
    animation: none;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(31, 232, 84, 0.4);
    }

    70% {
        box-shadow: 0 0 0 50px rgba(0, 204, 255, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(0, 204, 255, 0);
    }
}

@keyframes pulseError {
    0% {
        box-shadow: 0 0 0 0 rgba(237, 10, 10, 0.6);
    }

    70% {
        box-shadow: 0 0 0 50px rgba(0, 204, 255, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(0, 204, 255, 0);
    }
}

.KpiContainer {
    background-color: #ffffff;
    border-radius: 8px;
    width: 100%;
    height: 100%;
}

.KpiContainer span {
    font-size: medium;
    font-weight: bold;
}