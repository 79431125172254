.PageGridContent {
    margin-top: 0px;
    height: 200px;
}

.NumberCard {
    background-color: white;
    height: 150px;
    max-width: 350px;
    border-radius: 8px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.NumberCard:hover {
    cursor: pointer;
}

.NumberCardSmall {
    background-color: rgb(237, 236, 236);
    width: 100%;
    height: 100px;
    max-width: 200px;
    border-radius: 8px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01), 0 6px 6px rgba(0, 0, 0, 0.10);
    margin: 40px;
}

.NumberCardSmall:hover {
    cursor: pointer;
}

.SemanticStatisticCard {
    width: 100%;
    height: 100%;
    padding-top: 30px;
}

/* Dashboard.css */
.grid-row-compact {
    padding: 10px 0 !important;
}

.grid-column-compact {
    padding-left: 5px !important;
    padding-right: 5px !important;
}