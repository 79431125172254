.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.logo {
  margin-top: 60px;
}

.NavBarColumn {
  margin-left: 5px;
  border-style: none solid none none;
  border-width: 1px;
  border-color: lightgray;
}

.NavBarColumnLogin {
  text-align: right;
  padding-right: 30px !important;
}

.TopRow {
  border-color: #f5f5f5;
  border-style: none none solid none;
  border-width: 0.05em;
}

.ContentRow {
  padding-top: 0px !important;
  height: 1200px;
}

.OutletColumn {
  background-color: #f5f5f5;
}

.avatar {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-top: 7px;
}

.PageContent {
  width: 100%;
  width: 100%;
  height: 100%;
}

.MenuItemText {
  color: rgb(255, 255, 255, 0.4) !important;
  font-size: large;
  font-family: "Open Sans", sans-serif;
}

.MenuItemTextActive {
  color: rgb(56, 124, 36) !important;
  font-size: large;
  background-color: #ffffff !important;
  font-family: "Open Sans", sans-serif;
  font-weight: bold !important;
}

.MenuItemIcon {
  width: 20px;
  margin-right: 20px !important;
  color: rgb(255, 255, 255, 0.4);
}

.MenuItemIconActive {
  width: 20px;
  margin-right: 20px !important;
  color: rgb(56, 124, 36);
}

.TableRowClickable {
  cursor: pointer;
}

.SideBarColumn {
  background-color: rgb(56, 124, 36);
}

.Sidebar {
  width: 110%;
  padding-top: 10px;

  height: 100%;
}

.NumberCard {
  transition: transform 0.2s ease-in-out;
}

.NumberCard:hover {
  transform: translateY(-5px);
  /* Slight lift effect */
}

.EliusChart {
  border-color: rgb(240, 240, 240);
  border-style: solid;
  border-radius: 2%;
  margin-bottom: 30px;
}