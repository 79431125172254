#progressInput {
    margin: 20px auto;
    width: 30%;
}

.circle-container {
    margin-left: 10px;
    float: left;
}

.circle-background,
.circle-progress {
    fill: none;
}

.circle-background {
    stroke: #ddd;
}

.circle-progress-bad {
    stroke: red;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: #fdfdfd;
}

.circle-text-bad {
    font-size: 1.6em;
    font-weight: bold;
    fill: red;
}

.circle-progress-good {
    stroke: green;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: #fdfdfd;
}

.circle-text-good {
    font-size: 1.6em;
    font-weight: bold;
    fill: green;
}

.circleDescription {
    padding-top: 15px;
}

.ChartBackground {
    background-color: #ffffff;
    border-radius: 8px;
}

.ChartContainerDetailsChart{
    min-height: 400px;
    min-width: 80%;
}