.modern-ac-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.modern-ac-card {
    min-width: 300px;
    padding: 20px;
    border-radius: 15px;
    background: linear-gradient(136deg, #fafaff, #dee7f4) !important;
    box-shadow: 0px 4px 10px rgba(91, 50, 0, 0.3) !important;
    text-align: center;
    min-height: 300px !important;
}

.ac-header {
    display: block;
    justify-content: space-between;
    align-items: center;
    font-weight: bold !important;
    font-size: 1.4rem !important;
    margin-bottom: 30px !important;
    color: #00467b !important;
}

.ac-temperature-display {
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
    gap: 5px; 
    margin-top: 10px;
    width: 100%;
    text-align: center; 
}

.temperature-bubble {
    padding-bottom: 4px;
    border-radius: 20px;
    font-size: 1rem;
    width: 100% !important;
    font-weight: bold;
    color: #00467b;
}

.temperature-bubble.green {
    background: #019f19;
}

.temperature-bubble.red {
    background: #ce0000;
}

.ac-controls {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 10px;
    margin-bottom: 30px;
}

.mode-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
}

.mode-icon {
    opacity: 0.3;
    transition: opacity 0.3s ease-in-out;
    font-size: x-large;
}

.mode-icon.active {
    opacity: 1;
    color: #001e55;
    font-size: xx-large;
    /* Highlight color for the active mode */
}

.power-button {
    margin-top: 35px !important;
    background-color: #e0e0e0 !important;
    color: #555 !important;
    transition: background 0.3s ease-in-out;
}

.power-button.on {
    background-color: #007bff !important;
    color: white !important;
}

.power-button.off {
    background-color: #e0e0e0 !important;
    color: #888 !important;
}